import React from "react";
import Carousel from "../component/Carousel";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "./PagesCss/QuemSomos.css";

function QuemSomos() {
  const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];

  const QuemSomosCarousel = [
    { src: '/assets/Video/DamaJustica.mp4', showControls: false }
  ];

  const QuemSomosCarouselFotos = [
    { src: '/assets/img/Sede.jpg' },
    { src: '/assets/img/Sede1.jpg' },
    { src: '/assets/img/Sede2.jpg' },
    { src: '/assets/img/Sede3.jpg' },
    { src: '/assets/img/Sede4.jpg' },
    { src: '/assets/img/Sede5.jpg' },
    { src: '/assets/img/Sede6.jpg' },
    { src: '/assets/img/Sede7.jpg' },
    { src: '/assets/img/Sede8.jpg' },
    { src: '/assets/img/Sede9.jpg' }
  ];

  return (
    <div className="flex-page-container"><br/>
      <div className="none"><Carousel videos={QuemSomosCarousel} /></div>
      <section id="page-content">
        <section id="presentation">
          <div className="div-small">
            <div><br/><br/><br/><br/><br/><br/><br/><br/>
              <h1 className="h1-centro">Nossa História</h1>
              <p>
                Somos Custódio Quessada Lima Advogados. Uma banca de advocacia composta por mais de duas dezenas de colaboradores que atuam simultaneamente em 19 estados brasileiros. Desde a sua fundação, a CQL|ADV sempre foi focada na renegociação de dívidas e contratos bancários. Contudo, no início, o escritório atuava exclusivamente com contratos bancários ligados ao crédito rural, área na qual a banca mantém posição de destaque até hoje.
                <br/> <br/> Em 2022, decidimos manter o enfoque na discussão de questões bancárias, porém com o leque mais aberto, passando a atuar também com dívidas contraídas por aposentados e servidores públicos referentes a empréstimos consignados com desconto em folha de pagamento. Assim, possuímos atualmente dois departamentos divididos de acordo com sua área de atuação especializada: um voltado exclusivamente para questões ligadas ao direito agrário e do agronegócio, e outro que se dedica à solução de problemas ligados a contratos bancários de crédito consignado. A missão de ambos é entregar paz aos clientes da CQL|ADV, por meio da proteção jurídica de seus direitos.
              </p>
              <div className="image-container2">
              <h1 className="h1-centro2">Nossos Prêmios</h1>  
             <div className="image-item">
                  <img src="assets/img/PremioLAQI.jpeg" alt="Imagem 1" className="custom-image" />
                  <img src="assets/img/PremioTQB.jpeg" alt="Imagem 2" className="custom-image" />
              </div></div><br/>
              <p>
                Essas conquistas representam o reconhecimento de nosso compromisso em atender às necessidades de nossos clientes e garantir que seus direitos sejam sempre assegurados. Gostaríamos de expressar nossa profunda gratidão a cada um de nossos clientes, pois sem seu apoio e confiança em nossos serviços, essa conquista não seria possível. Desde o início, nossa missão tem sido oferecer serviços jurídicos excepcionais, focados em garantir ao produtor rural a garantia de seu direito!
              </p>
            </div>
          </div>
          <div className="div-small">
            <br/><br/>
            <ImageGallery items={QuemSomosCarouselFotos.map(photo => ({ original: photo.src, thumbnail: photo.src }))} />
            <div className="margin"></div>
          </div>
        </section>
      </section>
    </div>
  );
}

export default QuemSomos;
