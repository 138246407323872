import React from "react";
import Carousel from "../component/Carousel";
import "./PagesCss/Contato.css";
import BotaoWhatsapp from "../component/BotaoWhatsapp";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap';

function Contato() {
  const contatoCarousel = [{ src: "/assets/Video/Contato.mp4", showControls: false }];

  return (
    <div className="flex-page-container">
      <div className="none">
        <Carousel videos={contatoCarousel} />
      </div>
      <div className="contact-content">
        <br /><br /><br /><br /><br /><br />
        <section id="contact">
          <div className="generic-page">
            <div className="contato-box">
              <div className="contato-row no-gutters">
                <div className="Contato-col-md-8 profile">
                  <div className="contato-row no-gutters h-100">
                    <div className="col-lg-10 col-xl-9 info">
                      <h1>São Paulo</h1>
                      <p>
                        Av. Nove de Julho, 3229, 11° andar, conj.1104<br />
                        Jardim Paulista CEP: 01407-000 - São Paulo - SP - Brasil
                      </p>
                      <p>
                        <strong>Tel:</strong> 0800 800 8002<br />
                      </p>
                      <p className="link"></p>
                      <a href="https://maps.app.goo.gl/tFgpFhqnxr7rAbLM6" target="_blank" rel="noopener noreferrer">Mapa de localização</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="contact-content">
        <section id="contact">
          <div className="generic-page">
            <div className="contato-box">
              <div className="contato-row no-gutters">
                <div className="Contato-col-md-8 profile">
                  <div className="contato-row no-gutters h-100">
                    <div className="col-lg-10 col-xl-9 info">
                      <h1>Novo Horizonte (Sede)</h1>
                      <p>
                        Rua Trajano Machado, 595<br />
                        Centro CEP: 22431-050 - Novo Horizonte - SP - Brasil
                      </p>
                      <p>
                        <strong>Tel:</strong> 0800 800 8002<br />
                      </p>
                      <p className="link"></p>
                      <a href="https://maps.app.goo.gl/5PAv1h5BnPT2BfwG9" target="_blank" rel="noopener noreferrer">Mapa de localização</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="contact-content">
        <section id="contact">
          <div className="generic-page">
            <div className="contato-box">
              <div className="contato-row no-gutters">
                <div className="Contato-col-md-8 profile">
                  <div className="contato-row no-gutters h-100">
                    <div className="col-lg-10 col-xl-9 info">
                      <h1>São José do Rio Preto</h1>
                      <p>
                        Rua Santos Dumont, 79<br />
                        Vila Ercília, CEP: 15013-100 - São Paulo - SP - Brasil
                      </p>
                      <p>
                        <strong>Tel:</strong> 0800 800 8002<br />
                      </p>
                      <p className="link"></p>
                      <a href="https://maps.app.goo.gl/44oLcq82hQRcVzBR6" target="_blank" rel="noopener noreferrer">Mapa de localização</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section id="rankings">
        <div className="trabalhe-container">
          <div className="trabalhe-row">
            <div className="col-12 trabalhe-tab">
              <div className="row box no-gutters">
                <div className="col-lg-3 align-self-center">
                  <h4>Política restritiva sobre rankings</h4>
                </div>
                <div className="trabalhe-col-lg-9 text">
                  Não participamos de ou damos informações a publicações classificadoras de escritórios de advocacia (rankings) com uso de informações confidenciais de clientes. Também não pagamos por espaço editorial ou publicitário. Isso pode levar a omissão ou distorção de informações relativas a nossas atividades em tais publicações. Assim, a visita a nosso site é a maneira mais adequada de conhecer nossas atividades.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BotaoWhatsapp />
    </div>
  );
}

export default Contato;
