import React from 'react';
import { Link } from 'react-router-dom';
import './ComponenteCSS/AreasAtuacao.css';
import AreasHome from './AreasHome';

function AreasAtuacao() {
  return (
    <div>
      <section id="AreasAtuacao" className="AreasAtuacao-section"></section>
      <AreasHome />

      {/* Seção Agronegócios */}
      <div id="agronegocios"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section  className="AreasAtuacao-section">    
        <h1>Agronegócios</h1>
        <p1><br/>
          ● &nbsp; Crédito Rural<br/>
          ● &nbsp; Alongamento de Dívidas<br/>
          ● &nbsp; Defesa em Execuções<br/>
          ● &nbsp; CPR (Cédula de Produto Rural)<br/>
          ● &nbsp; Impenhorabilidade da Pequena Propriedade Rural<br/>
          ● &nbsp; Impenhorabilidade de Máquinas e Implementos Agrícolas<br/>
          ● &nbsp; Seguro Agrícola<br/>
          ● &nbsp; Ação Declaratória e Indenizatória<br/>
          ● &nbsp; Reintegração e Manutenção de Posse<br/>
          ● &nbsp; Interdito Proibitório<br/>
          ● &nbsp; Usucapião<br/>
        </p1>
        <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 
 
      </section>

          {/* Seção Bancário e Transações Financeiras */}
    <div id="bancario-e-transacoes-financeiras"></div>
    <br/> <br/> <br/> <br/>   <br/>
    <section className="AreasAtuacao-section">
      <h1>Bancário e Transações Financeiras</h1>
      <p1><br />
        ● &nbsp; Operações de Crédito<br />
        ● &nbsp; Estruturação de financiamentos<br />
        ● &nbsp; Negociação de contratos bancários<br />
        ● &nbsp; Regulação Financeira<br />
        ● &nbsp; Assessoria em compliance regulatório<br />
        ● &nbsp; Consultoria em operações financeiras<br />
      </p1>
      <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 
    </section>


{/* Seção Biocombustíveis */}
<div id="biocombustiveis"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section  className="AreasAtuacao-section">
      <h1>Biocombustíveis</h1>
        <p1><br />
          ● &nbsp; Regulação e Licenciamento<br />
          ● &nbsp; Consultoria em conformidade regulatória<br />
          ● &nbsp; Obtenção de licenças e autorizações<br />
          ● &nbsp; Parcerias e Investimentos<br />
          ● &nbsp; Estruturação de joint ventures e parcerias estratégicas<br />
          ● &nbsp; Captação de recursos e financiamentos<br />
        </p1>
          <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

      </section>

            {/* Seção Contencioso Cível */}
      <div id="contencioso-civel"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section className="AreasAtuacao-section">
        <h1>CONTENCIOSO CÍVEL</h1>
        <p1><br />
          ● &nbsp; Litígios Contratuais<br />
          ● &nbsp; Disputas contratuais e comerciais<br />
          ● &nbsp; Mediação e arbitragem<br />
          ● &nbsp; Responsabilidade Civil<br />
          ● &nbsp; Ações indenizatórias<br />
          ● &nbsp; Defesa em processos de responsabilidade civil<br />
        </p1>
        <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5>
      </section>


{/* Seção Contratos */}
<div id="contratos"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section  className="AreasAtuacao-section">
      <h1>Contratos</h1>
<p1><br />
  ● &nbsp; Elaboração e Revisão de Contratos<br />
  ● &nbsp; Contratos comerciais e empresariais<br />
  ● &nbsp; Contratos de prestação de serviços<br />
  ● &nbsp; Negociação Contratual<br />
  ● &nbsp; Assessoria em negociações complexas<br />
  ● &nbsp; Mediação e arbitragem de conflitos contratuais<br />
</p1>          
<br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

</section>

{/* Seção Direito Penal Financeiro */}
<div id="direito-penal-financeiro"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section  className="AreasAtuacao-section">
      <h1>Direito Penal Financeiro</h1>
      <p1><br />
        ● &nbsp; Crimes Financeiros<br />
        ● &nbsp; Defesa em investigações e processos criminais<br />
        ● &nbsp; Consultoria em compliance e prevenção de crimes financeiros<br />
      </p1>
          <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

</section>

{/* Seção Energia Renovável */}
<div id="energia-renovavel"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section  className="AreasAtuacao-section">
      <h1>Energia Renovável</h1>
        <p1><br />
          ● &nbsp; Projetos de Energia Solar e Eólica<br />
          ● &nbsp; Estruturação jurídica de projetos<br />
          ● &nbsp; Consultoria em regulamentação e licenciamento<br />
          ● &nbsp; Financiamento de Projetos<br />
          ● &nbsp; Estruturação de financiamentos e parcerias<br />
          ● &nbsp; Negociação de contratos de compra de energia (PPA)<br />
        </p1>
          <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

</section>

{/* Seção Família e Sucessões */}
<div id="familia-e-sucessoes"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section  className="AreasAtuacao-section">
      <h1>Família e Sucessões</h1>
      <p1><br />
        ● &nbsp; Divórcio e Separação<br />
        ● &nbsp; Divórcio consensual e litigioso<br />
        ● &nbsp; Partilha de bens<br />
        ● &nbsp; Inventário e Partilha<br />
        ● &nbsp; Inventário judicial e extrajudicial<br />
        ● &nbsp; Planejamento sucessório<br />
      </p1>          <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

</section>

{/* Seção Gestão Patrimonial */}
<div id="gestao-patrimonial"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section  className="AreasAtuacao-section">
      <h1>Gestão Patrimonial</h1>
      <p1><br />
        ● &nbsp; Planejamento Sucessório<br />
        ● &nbsp; Estruturação de heranças e testamentos<br />
        ● &nbsp; Criação de holdings familiares<br />
        ● &nbsp; Proteção Patrimonial<br />
        ● &nbsp; Blindagem de bens<br />
        ● &nbsp; Estruturação de trusts e fundações<br />
      </p1>
          <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

</section>

{/* Seção Litígios Societários e Comerciais */}
<div id="litigios-societarios-e-comerciais"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section  className="AreasAtuacao-section">
      <h1>Litígios Societários e Comerciais</h1>
      <p1><br />
        ● &nbsp; Conflitos entre Sócios<br />
        ● &nbsp; Mediação e arbitragem de disputas societárias<br />
        ● &nbsp; Assessoria em acordos de sócios<br />
        ● &nbsp; Contencioso Comercial<br />
        ● &nbsp; Defesa em litígios comerciais<br />
        ● &nbsp; Negociação de acordos extrajudiciais<br />
      </p1>
          <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

</section>

{/* Seção Negócios Imobiliários */}
<div id="negocios-imobiliarios"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section  className="AreasAtuacao-section">
              <h1>NEGÓCIOS IMOBILIÁRIOS</h1>
          <p1><br />
            ● &nbsp; Aquisição e Venda de Imóveis<br />
            ● &nbsp; Due diligence imobiliária<br />
            ● &nbsp; Regularização de imóveis<br />
            ● &nbsp; Locação e Administração de Imóveis<br />
            ● &nbsp; Contratos de locação<br />
            ● &nbsp; Gestão de condomínios<br />
          </p1>          <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

</section>

{/* Seção Previdenciário */}
<div id="previdenciario"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section  className="AreasAtuacao-section">
      <h1>Previdenciário</h1>
      <p1><br />
        ● &nbsp; Consultoria Previdenciária<br />
        ● &nbsp; Planejamento de aposentadoria<br />
        ● &nbsp; Assessoria em benefícios previdenciários<br />
        ● &nbsp; Contencioso Previdenciário<br />
        ● &nbsp; Defesa em ações judiciais e administrativas<br />
        ● &nbsp; Revisão de benefícios e concessões<br />
      </p1>
          <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

  </section>

      {/* Seção Propriedade Intelectual */}
      <div id="propriedade-intelectual"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section className="AreasAtuacao-section">
      <h1>Propriedade Intelectual</h1>
      <p1><br />
        ● &nbsp; Registro de Marcas e Patentes<br />
        ● &nbsp; Proteção de direitos autorais<br />
        ● &nbsp; Assessoria em processos de registro<br />
        ● &nbsp; Litígios de Propriedade Intelectual<br />
        ● &nbsp; Defesa de direitos de propriedade intelectual<br />
        ● &nbsp; Ação contra infrações e pirataria<br />
      </p1>
          <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

      </section>

      {/* Seção Recuperação Tributária */}
      <div id="recuperacao-tributaria"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section className="AreasAtuacao-section">
        <h1>Recuperação Tributária</h1>
        <p1><br/>
          ● &nbsp; Revisão de Tributos Pagos<br/>
          ● &nbsp; Análise de pagamento de tributos indevidos ou a maior<br/>
          ● &nbsp; Restituição de tributos federais, estaduais e municipais<br/>
          ● &nbsp; Compensação Tributária<br/>
          ● &nbsp; Utilização de créditos tributários<br/>
          ● &nbsp; Compensação de impostos futuros<br/>
          ● &nbsp; Planejamento Tributário<br/>
          ● &nbsp; Estruturação fiscal eficiente<br/>
          ● &nbsp; Redução da carga tributária<br/> 
        </p1>
        <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 
      </section>


          {/* Seção Reestruturação e Insolvência */}
          <div id="reestruturacao-e-insolvencia"></div>
                <br/> <br/> <br/> <br/>   <br/>
                <section  className="AreasAtuacao-section">
                <h1>Reestruturação e Insolvência</h1>
          <p1><br />
            ● &nbsp; Recuperação Judicial e Extrajudicial<br />
            ● &nbsp; Assessoria em processos de recuperação<br />
            ● &nbsp; Negociação com credores<br />
            ● &nbsp; Falência<br />
            ● &nbsp; Assessoria em processos de falência<br />
            ● &nbsp; Liquidação de ativos<br />
          </p1>
                    <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

          </section>

          {/* Seção Relações de Consumo */}
          <div id="relacoes-de-consumo"></div>
          <br/> <br/> <br/> <br/>   <br/>
          <section  className="AreasAtuacao-section">
          <h1>Relações de Consumo</h1>
          <p1><br />
            ● &nbsp; Consultoria em Direito do Consumidor<br />
            ● &nbsp; Adequação às normas de defesa do consumidor<br />
            ● &nbsp; Assessoria em publicidade e marketing<br />
            ● &nbsp; Contencioso de Consumo<br />
            ● &nbsp; Defesa em ações de consumidores<br />
            ● &nbsp; Mediação e resolução de conflitos<br />
          </p1>
              <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

    </section>

        {/* Seção Relações Governamentais */}
        <div id="relacoes-governamentais"></div>
              <br/> <br/> <br/> <br/>   <br/>
              <section  className="AreasAtuacao-section">
              <h1>Relações Governamentais</h1>
        <p1><br />
          ● &nbsp; Lobby e Advocacy<br />
          ● &nbsp; Representação de interesses junto a órgãos públicos<br />
          ● &nbsp; Monitoramento de legislação e políticas públicas<br />
          ● &nbsp; Consultoria em Licitações<br />
          ● &nbsp; Assessoria em processos licitatórios<br />
          ● &nbsp; Elaboração de propostas e recursos<br />
        </p1>
              <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

    </section>

      {/* Seção Saúde e Acessibilidade */}
      <div id="saude-e-acessibilidade"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section  className="AreasAtuacao-section">
      <h1>Saúde e Acessibilidade</h1>
        <p1><br />
          ● &nbsp; Regulação de Saúde<br />
          ● &nbsp; Consultoria em conformidade regulatória<br />
          ● &nbsp; Assessoria em licenciamento de produtos e serviços de saúde<br />
          ● &nbsp; Direitos das Pessoas com Deficiência<br />
          ● &nbsp; Consultoria em acessibilidade e inclusão<br />
          ● &nbsp; Defesa de direitos e ações judiciais<br />
        </p1>
          <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

</section>

    {/* Seção Seguros e Resseguros */}
    <div id="seguros-e-resseguros"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section  className="AreasAtuacao-section">
      <h1>Seguros e Resseguros</h1>
      <p1><br />
        ● &nbsp; Consultoria em Seguros<br />
        ● &nbsp; Análise e elaboração de apólices<br />
        ● &nbsp; Gestão de sinistros<br />
        ● &nbsp; Resseguros<br />
        ● &nbsp; Estruturação de operações de resseguro<br />
        ● &nbsp; Negociação de contratos de resseguro<br />
      </p1>
          <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

</section>

    {/* Seção Tecnologia */}
    <div id="tecnologia"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section  className="AreasAtuacao-section">
      <h1>Tecnologia</h1>
    <p1><br />
        ● &nbsp; Proteção de Dados<br />
        ● &nbsp; Adequação à LGPD<br />
        ● &nbsp; Políticas de privacidade e segurança da informação<br />
        ● &nbsp; Contratos de Tecnologia<br />
        ● &nbsp; Contratos de software e licenciamento<br />
        ● &nbsp; Acordos de desenvolvimento tecnológico<br />
</p1>
          <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

</section>

{/* Seção Trabalhista */}
<div id="trabalhista"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section  className="AreasAtuacao-section">
      <h1>Trabalhista</h1>
        <p1><br />
          ● &nbsp; Consultoria Trabalhista<br />
          ● &nbsp; Compliance trabalhista<br />
          ● &nbsp; Gestão de conflitos e negociações sindicais<br />
          ● &nbsp; Contencioso Trabalhista<br />
          ● &nbsp; Defesa em ações trabalhistas<br />
          ● &nbsp; Acordos extrajudiciais<br />
        </p1>
          <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

</section>
      {/* Seção Recuperação Tributária */}
      <div id="tributario"></div>
      <br/> <br/> <br/> <br/>   <br/>
      <section className="AreasAtuacao-section">

      <h1>Tributário</h1>
        <p1><br />
          ● &nbsp; Planejamento Tributário<br />
          ● &nbsp; Estruturação fiscal eficiente<br />
          ● &nbsp; Redução da carga tributária<br />
          ● &nbsp; Contencioso Tributário<br />
          ● &nbsp; Defesa em processos administrativos e judiciais<br />
          ● &nbsp; Consultoria em questões tributárias complexas<br />
        </p1>

          <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

      </section>

        {/* Seção Venture Capital e Startups */}
        <div id="venture-capital-e-startups"></div>
              <br/> <br/> <br/> <br/>   <br/>
      <section  className="AreasAtuacao-section">
      <h1>Venture Capital e Startups</h1>
        <p1><br />
          ● &nbsp; Estruturação de Investimentos<br />
          ● &nbsp; Negociação de termos de investimento<br />
          ● &nbsp; Assessoria em rodadas de financiamento<br />
          ● &nbsp; Governança Corporativa<br />
          ● &nbsp; Estruturação de acordos de sócios<br />
          ● &nbsp; Consultoria em compliance e governança<br />
        </p1>
          <br/><h5><Link to="/areas#AreasAtuacao">Voltar ao topo</Link></h5> 

      </section>
    </div>
  );
}

export default AreasAtuacao;