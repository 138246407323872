import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import "./PagesCss/TermosDeUso.css";

function TermosDeUso() {
  return (
    <div className="flex-page-container">
      <section id="page-content">
        <section id="presentation">
          <div className="div-small">
            <div>
              <br /><br /><br /><br /><br /><br /><br /><br />
              <h1 className="h1-centro">Termos de Uso</h1>
              <p>
                Estes termos de uso regulamentam a interação entre Custódio Quessada Lima Advogados e o indivíduo que acessar o sítio eletrônico www.cqladv.com
              </p>
              <p>
                1. Conteúdo<br />
                O sítio eletrônico disponibiliza informações sobre Custódio Quessada Lima Advogados e seus profissionais, além de artigos, boletins, newsletters, notícias, links para sites externos e diversas imagens (“Conteúdo”).
              </p>
              <p>
                As informações sobre Custódio Quessada Lima Advogados e seus advogados são apresentadas para visualização no sítio eletrônico e não devem ser tratadas pelo Usuário sem autorização expressa ou legítimo interesse relacionado à contratação de serviços advocatícios ou comunicação profissional.
              </p>
              <p>
                Os artigos e boletins refletem a opinião profissional de seus autores no momento de sua publicação. Devem ser interpretados de acordo com a data de sua produção, não constituem aconselhamento ou parecer jurídico e não devem ser aplicados a situações concretas sem a devida orientação formal de Custódio Quessada Lima Advogados.
              </p>
              <p>
                As notícias narram fatos que podem ou não incluir a opinião de Custódio Quessada Lima Advogados ou informar sobre eventos a serem realizados. Tais eventos podem ser alterados ou cancelados sem prévia atualização no sítio eletrônico. As notícias devem ser interpretadas conforme os mesmos critérios aplicáveis aos artigos e boletins.
              </p>
              <p>
                Os links para sites externos são fornecidos como conveniência para o Usuário, e seu conteúdo não é de responsabilidade de Custódio Quessada Lima Advogados.
              </p>
              <p>
                As imagens ilustram as informações no sítio eletrônico, tornando a interação mais agradável. Sua reprodução, total ou parcial, é proibida, salvo autorização expressa de Custódio Quessada Lima Advogados ou do autor indicado. Imagens de domínio público serão devidamente identificadas.
              </p>
              <p>
                2. Propriedade Intelectual<br />
                Todo o Conteúdo é propriedade intelectual de Custódio Quessada Lima Advogados, exceto quando pertencente a terceiros que consentiram com sua reprodução específica no sítio eletrônico ou quando for de domínio público.
              </p>
              <p>
                O Conteúdo tem a finalidade de informar o Usuário e não deve ser utilizado de outra forma, a menos que expressamente autorizado por Custódio Quessada Lima Advogados. O uso lícito do Conteúdo pelo Usuário inclui exclusivamente a leitura e visualização no sítio eletrônico. Para artigos, boletins e notícias, o uso lícito também inclui: (i) download e armazenamento; (ii) impressão; (iii) compartilhamento físico ou digital, desde que contenha referência ao sítio eletrônico e não seja para fins comerciais; e (iv) citação com devida autoria e local de acesso, conforme normas da ABNT.
              </p>
              <p>
                3. Política de Privacidade<br />
                Custódio Quessada Lima Advogados possui uma Política de Privacidade que regula o tratamento de dados pessoais dos Usuários.
              </p>
              <p>
                4. Responsabilidade de Custódio Quessada Lima Advogados<br />
                Custódio Quessada Lima Advogados não se responsabiliza por quaisquer danos que possam ocorrer ao Usuário durante a interação com o sítio eletrônico.
              </p>
              <p>
                5. Responsabilidade do Usuário<br />
                O Usuário compromete-se a seguir rigorosamente estes Termos de Uso e reconhece sua responsabilidade por qualquer dano causado a Custódio Quessada Lima Advogados decorrente do descumprimento destes Termos de Uso.
              </p>
              <p>
                6. Legislação Aplicável e Solução de Controvérsias<br />
                Para questões relativas a estes Termos de Uso, aplicar-se-á a legislação da República Federativa do Brasil, sendo o foro de São Paulo, Estado de São Paulo, eleito para a resolução de conflitos.
              </p>
            </div>
            <br /><br /><br /><br /><br />
          </div>
        </section>
      </section>
    </div>
  );
}

export default TermosDeUso;
