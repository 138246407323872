import React from 'react';
import { Link } from 'react-router-dom';
import './ComponenteCSS/AreasHome.css';

const AreasHome = () => {
  return (
    <section id="areas-home">
      <div className="areas-home-row">
      <br/><br/><br/><br/><br/><br/>
        <div className="areas-home-offset-md-1 areas-home-col-md-11 areas-home-box">
          <h4>Áreas de Atuação</h4>
          <div className="areas-home-list">
          <div className="areas-home-row areas-home-no-gutters">
  <div className="areas-home-col-lg-6 box"> {/* Adiciona a classe box aqui */}
    <ul>
      <li><Link to="/areas/#agronegocios">AGRONEGÓCIOS➤</Link></li>
      <li><Link to="/areas/#bancario-e-transacoes-financeiras">BANCÁRIO E TRANSAÇÕES FINANCEIRAS➤</Link></li>  
      <li><Link to="/areas/#biocombustiveis">BIOCOMBUSTÍVEIS➤</Link></li>
      <li><Link to="/areas/#contencioso-civel">CONTENCIOSO CÍVEL➤</Link></li>    
      <li><Link to="/areas/#contratos">CONTRATOS➤</Link></li>
      <li><Link to="/areas/#direito-penal-financeiro">DIREITO PENAL FINANCEIRO➤</Link></li>
      <li><Link to="/areas/#energia-renovavel">ENERGIA RENOVÁVEL➤</Link></li>
      <li><Link to="/areas/#familia-e-sucessoes">FAMÍLIA E SUCESSÕES➤</Link></li>
      <li><Link to="/areas/#gestao-patrimonial">GESTÃO PATRIMONIAL➤</Link></li>
      <li><Link to="/areas/#litigios-societarios-e-comerciais">LITÍGIOS SOCIETÁRIOS E COMERCIAIS➤</Link></li>
      <li><Link to="/areas/#negocios-imobiliarios">NEGÓCIOS IMOBILIÁRIOS➤</Link></li>
      <li><Link to="/areas/#previdenciario">PREVIDENCIÁRIO➤</Link></li>
    </ul>
  </div>
  <div className="areas-home-col-lg-6 box"> {/* Adiciona a classe box aqui */}
    <ul>
      <li><Link to="/areas/#propriedade-intelectual">PROPRIEDADE INTELECTUAL➤</Link></li>
      <li><Link to="/areas/#recuperacao-tributaria">RECUPERAÇÃO TRIBUTÁRIA➤</Link></li>
      <li><Link to="/areas/#reestruturacao-e-insolvencia">REESTRUTURAÇÃO E INSOLVÊNCIA➤</Link></li>
      <li><Link to="/areas/#relacoes-de-consumo">RELAÇÕES DE CONSUMO➤</Link></li>
      <li><Link to="/areas/#relacoes-governamentais">RELAÇÕES GOVERNAMENTAIS➤</Link></li>
      <li><Link to="/areas/#saude-e-acessibilidade">SAÚDE E ACESSIBILIDADE➤</Link></li>
      <li><Link to="/areas/#seguros-e-resseguros">SEGUROS E RESSEGUROS➤</Link></li>
      <li><Link to="/areas/#tecnologia">TECNOLOGIA➤</Link></li>
      <li><Link to="/areas/#trabalhista">TRABALHISTA➤</Link></li>
      <li><Link to="/areas/#tributario">TRIBUTÁRIO➤</Link></li>
      <li><Link to="/areas/#venture-capital-e-startups">VENTURE CAPITAL E STARTUPS➤</Link></li>
      <br />
    </ul>
  </div>
</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AreasHome;
