import React from "react";
import AreasHome from "../component/AreasHome";

function AreasPage() {

  return (
    <React.StrictMode>
 <br/>
      <div>
            <AreasHome/>
          </div>
      </React.StrictMode>
  );
}

export default AreasPage;
