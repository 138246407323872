import React from "react";
import AreasAtuacao from "./AreasAtuacao";

function Areas() {

  return (
    <React.StrictMode>
      <AreasAtuacao />
    </React.StrictMode>
  );
}

export default Areas;
