import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import "./PagesCss/LGPD.css"; // Certifique-se de que este caminho está correto para o CSS da página LGPD

function LGPD() {
  return (
    <div className="flex-page-container">
      <section id="page-content">
        <section id="presentation">
          <div className="div-small">
            <div>
              <br /><br /><br /><br /><br /><br /><br /><br />
              <h1 className="h1-centro">Política de Privacidade</h1>
              <p>
                A política de privacidade do sítio eletrônico de Custódio Quessada Lima Advogados tem o objetivo de dar transparência às atividades de tratamento de dados pessoais por nós realizadas e às nossas práticas para proteger a segurança e a privacidade de nossos usuários.
              </p>
              <p>
                Qualquer informação encaminhada por usuários será protegida segundo padrões rígidos de confidencialidade e apenas utilizada para a finalidade à qual foi coletada.
              </p>
              <p>
                A visitação ao sítio é anônima, sendo possível visualizar a maioria das informações disponíveis sem identificação. Não divulgamos os dados pessoais fornecidos por usuários, mas poderá usá-los para comunicações relativas a eventos promovidos, para circulação de boletins, newsletters e informativos sobre nossas atividades, sobre as atividades de nossos integrantes, sobre matérias de interesse geral ou com conteúdo jurídico relevante para negócios e interesses dos nossos clientes ou, ainda, para outras atividades promocionais e finalidades que visem a atender nosso legítimo interesse. Poderão também ser usados para outras finalidades indicadas especificamente no ato da coleta do dado no sítio.
              </p>
              <p>
                O sítio eletrônico utiliza cookies com a finalidade de permitir o seu correto e seguro funcionamento, identificar o último idioma utilizado pelo navegador do usuário e gerar estatísticas sobre a interação entre os usuários e o sítio, como local de acesso e tempo de navegação.
              </p>
              <p>
                Todos os dados pessoais coletados por meio de cookies são tratados conforme o legítimo interesse de proporcionar aos usuários um sítio eletrônico funcional, seguro e personalizado e de analisar a interação entre os usuários e o conteúdo do sítio.
              </p>
              <p>
                Empresas contratadas para fornecer eventuais serviços de apoio a este sítio devem aderir à política de privacidade do escritório e serão responsabilizadas caso não sigam nossas instruções ou descumpram a legislação em vigor.
              </p>
              <p>
                No que diz respeito às atividades de tratamento de dados pessoais mencionadas nesta Política de Privacidade, a Custódio Quessada Lima Advogados é qualificado como controlador, segundo a legislação vigente. O usuário que deseje obter informações, esclarecer dúvidas ou exercer os direitos previstos em lei deverá encaminhar um e-mail, no endereço contato@cqladv.com. A requisição será analisada e atendida no prazo legal ou, na falta deste, em prazo razoável, salvo se existirem razões de fato ou de direito que impeçam seu atendimento. Os usuários, na qualidade de titulares de dados pessoais, poderão requerer:
                a confirmação da existência de tratamento de seus dados pessoais;
                <br/>o acesso aos seus dados;
                <br/>a correção de seus dados incompletos, inexatos ou desatualizados;
                <br/>a anonimização, bloqueio ou eliminação de seus dados que sejam desnecessários, excessivos, ou tratados em desconformidade com o disposto na legislação aplicável;
                <br/>a portabilidade dos seus dados a outro prestador de serviços, de acordo com a regulamentação da Autoridade Nacional de Proteção de Dados - ANPD, observados os segredos comercial e industrial;
                <br/>a eliminação dos seus dados pessoais, exceto nas hipóteses previstas na legislação aplicável;
                <br/>a informação sobre a possibilidade de não fornecer seu consentimento e sobre as consequências da negativa;
                <br/>a revogação do seu consentimento, resguardado o interesse público que possa justificar a continuidade do tratamento ou a existência de outra base legal que autorize; e
                oposição a qualquer tratamento de dados pessoais com fundamento em uma das hipóteses em que o consentimento é dispensado, desde que tenha ocorrido o descumprimento ao disposto na legislação aplicável e resguardado o interesse público que possa justificar a continuidade do tratamento.
                <br/>
                Ao enviar seu currículo para nosso sítio, o usuário concorda que a Custódio Quessada Lima Advogados poderá armazená-lo pelo período de 2 (dois) anos e utilizá-lo no processo seletivo para demais vagas do escritório que se enquadrem no perfil do candidato. O candidato deverá se abster de compartilhar conosco dados sensíveis como relativos à origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, saúde ou vida sexual, bem como dado genético ou biométrico.
                <br/>
                Ao enviar qualquer dado pessoal, o usuário concorda com esta política de privacidade.
              </p>
            </div>
            <br /><br /><br /><br /><br />
          </div>
        </section>
      </section>
    </div>
  );
}

export default LGPD;
