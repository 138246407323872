import React from "react";
import Carousel from "../component/Carousel";
import "./PagesCss/ProcessoSeletivo.css";

function ProcessoSeletivo() {
  const ProcessoSeletivoCarousel = [
    { src: 'assets/Video/Direito.mp4', showControls: false }
  ];

  return (
    <React.StrictMode>
      <div className="flex-page-container">
        <div className="none"><Carousel videos={ProcessoSeletivoCarousel} /></div>
        <section id="recruiting">   
          <div className="div-small">
            <div className="margin-top"><h1>Processo Seletivo</h1></div><br/>
            <div className="trabalhe-row">
              <div className="trabalhe-col-lg-9">
                <p>Obrigado por se interessar pela nossa prática e pelo nosso processo de seleção de serviços jurídicos e empresariais.</p>
                <p>Nosso processo de seleção é baseado nas qualificações intelectuais, acadêmicas e profissionais e nas habilidades de comunicação dos candidatos. Buscamos promover o crescimento profissional dentro do escritório desde o estágio jurídico para cima; de fato, um número de pessoas inicialmente contratadas por nosso escritório como estagiários jurídicos agora são sócios do escritório.&nbsp;&nbsp;</p>
              </div>
              <div className="col-lg-3 trabalhe-resume">
                <p><a href="https://docs.google.com/forms/d/e/1FAIpQLSdMQLkc0W06x9uC9kJeEiv4CWH6yVGJ-DBFk_eMHfGgWALM0Q/viewform" className="btn trabalhe-custom1-btn">Envie seu Currículo</a></p>  
              </div>
            </div>
          </div>
        </section>
        <section id="rankings">
          <div className="trabalhe-container">
            <div className="trabalhe-row">
              <div className="col-12 trabalhe-tab">
                <div className="row box no-gutters">
                  <div className="col-lg-3 align-self-center">
                    <h4>Política restritiva sobre rankings</h4>
                  </div>
                  <div className="trabalhe-col-lg-9 text">
                    Não participamos de ou damos informações a publicações classificadoras de escritórios de advocacia (rankings) com uso de informações confidenciais de clientes. Também não pagamos por espaço editorial ou publicitário. Isso pode levar a omissão ou distorção de informações relativas a nossas atividades em tais publicações. Assim, a visita a nosso site é a maneira mais adequada de conhecer nossas atividades.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.StrictMode>
  );
}

export default ProcessoSeletivo;
