import React from "react";
import Carousel from "../component/Carousel";
import Cadastro from "../component/Cadastro";


function CadastroPage() {

  const AreasAtuacaoCarousel = [
    { src: '/assets/Video/Ceupb.mp4', showControls: false }
  ];

  return (
    <React.StrictMode>

      <Carousel videos={AreasAtuacaoCarousel} />
      <br/><br/><br/><br/>
      <Cadastro />
      <section id="rankings">
            <div className="trabalhe-container">
              <div className="trabalhe-row">
                <div className="col-12 trabalhe-tab">
                  <div className="row box no-gutters">
                    <div className="col-lg-3 align-self-center">
                      <h4>Política restritiva sobre rankings</h4>
                    </div>
                    <div className="trabalhe-col-lg-9 text">
                      Não participamos de ou damos informações a publicações classificadoras de escritórios de advocacia (rankings) com uso de informações confidenciais de clientes. Também não pagamos por espaço editorial ou publicitário. Isso pode levar a omissão ou distorção de informações relativas a nossas atividades em tais publicações. Assim, a visita a nosso site é a maneira mais adequada de conhecer nossas atividades.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


      </React.StrictMode>
      
      
  );
}

export default CadastroPage;
